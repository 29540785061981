
import { Component, Vue, Provide, Emit } from 'vue-property-decorator'
import { mobileRegExp } from '@/utils/reg'
import smsMixin from '@/mixins/sms'
import VerificationCode from '@/components/VerificationCode.vue'
import GlobalMixin from '@/mixins/global'
import getCurrentQuery from '@/utils/querystrings'

@Component({
  name: 'gdyd',
  components: {
    'verification-code': VerificationCode
  },
  mixins: [smsMixin, GlobalMixin]
})
export default class gdydPage extends Vue {
  @Provide() public isFirst: boolean = true
  @Provide() public mobile: string = ''
  @Provide() public policySelect: boolean = false
  @Provide() public smsType: number = 3 // smsType: 4代表是从良储公众号过来的链接
  @Provide() public grantType: number = 23
  @Provide() public apiError: string = ''

  public async created () {
    this.mobile = getCurrentQuery('phone') || ''
  }

  @Emit()
  public async refresh () {
  }

  @Emit()
  public async sendCode () {
    this.apiError = this.mobileError()
    if (this.apiError) {
      return
    }
    if (!this.policySelect) {
      this.$toasted.show('请阅读并勾选页面协议')
      return
    }
    (this.$refs.verification as any).sendCode()
    this.isFirst = false
  }
  @Emit()
  public mobileError () {
    if (!this.mobile) {
      return '手机号码不能为空'
    }
    return mobileRegExp.test(this.mobile) ? '' : '手机号格式不正确，请重新输入'
  }

  @Emit()
  public toLogin () {
    this.$router.replace(`/login${location.search}`)
  }

  @Emit()
  public selectPolicy () {
    this.policySelect = !this.policySelect
  }
}
